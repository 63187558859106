<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/scheme/top-img.png" alt="" />
      <!-- <div class="topImg-headline">
        <div class="topImg-title">大学生</div>
        <div class="topImg-text">心理健康教育与咨询整体解决方案</div>
      </div> -->
    </div>
    <div class="content-centre">
      <div class="centre-title">大学生心理健康工作常见问题</div>
      <div class="centre-box" v-for="item in centreOneList" :key="item.id">
        <div class="center-one-top">
          <div class="center-one-top-box">
            <img v-bind:src="item.url" alt="" />
            <p class="center-one-top-text">{{ item.title }}</p>
          </div>
        </div>
        <div class="centre-box-text">
          <ul>
            <li>{{ item.text }}</li>
            <li>{{ item.text2 }}</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 高等教育心理健康解决方案 -->
    <div class="content-two">
      <!-- <div class="centre-title">大学生心理健康工作常见问题</div> -->
      <div class="center-two-content">
        <div class="centre-title" style="padding-top: 2rem">大学生心理健康工作常见问题</div>
        <div class="center-two-contentImg">
          <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/fangan-1.png" alt="" />
        </div>
      </div>
    </div>
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="平台" name="first">
          <div class="content-centre">
            <h2>平台系统架构图</h2>
            <div class="pingtai">
              <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/schemeHigher-first.png" alt="" />
            </div>
          </div>
          <!-- 核心应用场景 -->
          <div class="content-four">
            <h2>核心应用场景</h2>
            <div class="content-changjing">
              <div class="content-four-box" v-for="item in changjingList" :key="item.id">
                <div class="changjingImg">
                  <img v-bind:src="item.url" alt="" />
                </div>
                <div class="changjing-box">
                  <p class="changjing-title">{{ item.title }}</p>
                  <p class="changjing-text">
                    {{ item.text }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="内容" name="second">
          <!-- 大学生心理健康课程资源 -->
          <div class="course content-centre">
            <h2>大学生心理健康课程资源</h2>
            <div class="content-course-box">
              <div class="content-course" v-for="item in centreTwoList" :key="item.id">
                <div class="courseImg">
                  <img v-bind:src="item.url" alt="" />
                </div>
                <p class="course-title">{{ item.title }}</p>
                <p class="course-text">{{ item.text }}</p>
              </div>
            </div>
          </div>
          <div class="zizhuBox">
            <h2>大学生心理健康自助学习资源</h2>
            <div class="zizhuImg">
              <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/ruanjian%20.png" alt="" />
            </div>
            <div class="content-centre zizhuList">
              <ul>
                <li>以视频、音频、图书、文章等为主要形式的心理自助资源 </li>
                <li>支持移动端学习，随时随地观看</li>
              </ul>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="培训" name="third">
          <div>
            <h2>个性化师资培训方案</h2>
            <div class="content-centre teachers">
              <div class="teachers-box" v-for="item in centreThreeList" :key="item.id">
                <div class="teachersImg"><img v-bind:src="item.url" alt="" /></div>
                <p class="teachers-title">{{ item.title }}</p>
                <div class="teachers-List">
                  <ul>
                    <li>{{ item.list1 }}</li>
                    <!-- <li>{{ item.list2 }}</li> -->
                    <!-- <li>{{ item.list3 }}</li> -->
                    <!-- <li style="padding-bottom: 1rem">{{ item.list4 }}</li> -->
                  </ul>
                </div>
              </div>
              <!-- <div class="teachers-box">
                <div class="teachersImg">
                  <img src="../assets/scheme/teachers-one.png" alt="" />
                </div>
                <p class="teachers-title">中心领导</p>
                <div class="teachers-List">
                  <ul>
                    <li>制定工作计划与活动安排</li>
                    <li>实施心理咨询案例常规督导</li>
                    <li>参与学生心理危机事件处理工作</li>
                    <li>心理健康教育教学和心理咨询工作</li>
                  </ul>
                </div>
              </div>
              <div class="teachers-box">
                <div class="teachersImg">
                  <img src="../assets/scheme/teachers-one.png" alt="" />
                </div>
                <p class="teachers-title">咨询师</p>
                <div class="teachers-List">
                  <ul>
                    <li>伦理守则</li>
                    <li>高校心理咨询流程</li>
                    <li>高校常见心理问题及对策</li>
                    <li>高校心理咨询理论及方法</li>
                  </ul>
                </div>
              </div>
              <div class="teachers-box">
                <div class="teachersImg">
                  <img src="../assets/scheme/teachers-one.png" alt="" />
                </div>
                <p class="teachers-title">辅导员</p>
                <div class="teachers-List">
                  <ul>
                    <li>突发事件应对办法</li>
                    <li>心理危机干预方式</li>
                    <li>常见心理问题及应对</li>
                    <li>学生访谈流程和方法</li>
                  </ul>
                </div>
              </div>
              <div class="teachers-box">
                <div class="teachersImg">
                  <img src="../assets/scheme/teachers-one.png" alt="" />
                </div>
                <p class="teachers-title">心理委员</p>
                <div class="teachers-List">
                  <ul>
                    <li>突发事件应对办法</li>
                    <li>重点关注工作内容</li>
                    <li>高校常见心理问题及应对n</li>
                    <li>心理健康知识宣传与普及</li>
                  </ul>
                </div>
              </div> -->
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="硬件" name="fourth">
          <div>
            <h2>心理健康教育空间环境建设方案</h2>
            <div class="content-centre">
              <div class="product-bottom">
                <div class="product-bottom-img" v-for="item in productList" :key="item.id">
                  <div class="product-bottom-img-hover">
                    <h4>{{ item.title }}</h4>
                  </div>
                  <img v-bind:src="item.src" alt="" />
                  <div class="product-bottom-img-text">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="jiansheBox" style="display: none">
            <h2>预期假设效果</h2>
            <div class="jianshe-list">
              <ul>
                <li>提供覆盖全校的普测服务，提供深度的普测数据分析</li>
                <li>针对普测数据形成智能筛查与精准干预的完整闭环管理机制</li>
                <li>提供系统化的心理必修课和选修课优质课程，提供针对性活动管理功能</li>
                <li>提供多种模式的咨询平台实现心理咨询不打烊，提供专家咨询服务</li>
                <li>实现普测、课程、活动、咨询等数据统一管理，提供科学全面及时的数据服务</li>
                <li>基于画像形成精准施策闭环管理，数据推动个性化指导，推动分类教育</li>
              </ul>
            </div>
          </div>
          <!-- 心灵通四统一 -->
          <h2 style="display: none">心灵通 “四统一”助力高校建设心理健康新平台</h2>
          <div class="yingjian-bj" style="display: none">
            <div class="yingjian-three-box">
              <div class="yingjian-three">
                <div class="yingjian-three-Img"><img src="../assets/scheme/yingjian-one-icon.png" alt="" /></div>
                <p class="yingjian-three-title">网络应用与隐私保障</p>
                <p class="yingjian-three-text">严格遵守心理健康教育法律、伦理等要求，不仅通过网络提高效率，同时采用非对称加密模式保障个人隐私</p>
              </div>
              <div class="yingjian-three">
                <div class="yingjian-three-Img"><img src="../assets/scheme/yingjian-two-icon.png" alt="" /></div>
                <p class="yingjian-three-title">发现问题与解决问题</p>
                <p class="yingjian-three-text">改变传统的工作模式，不仅通过测评等系统发现问题，同时也有后续干预与教育解决问题的方案</p>
              </div>
              <div class="yingjian-three">
                <div class="yingjian-three-Img"><img src="../assets/scheme/yingjian-three-icon.png" alt="" /></div>
                <p class="yingjian-three-title">过程管理与源头治理</p>
                <p class="yingjian-three-text">一方面强调危机干预的过程管理，同时注重通过教育从源头治理上解决心理健康长效机制问题</p>
              </div>
              <div class="yingjian-three">
                <div class="yingjian-three-Img"><img src="../assets/scheme/yingjian-four-icon.png" alt="" /></div>
                <p class="yingjian-three-title">规范管理与效率提升</p>
                <p class="yingjian-three-text">平台应用模式灵活，一方面注重规范管理，降低系统风险，同时注重通过信息化提高效率，减少教师重复性工作</p>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      centreOneList: [
        { url: require('../assets/scheme/one-icon.png'), title: '新生普测', text: '准确率不高导致筛查难度和工作量加大', text2: ' 缺乏对于普测结果的深度分析' },
        { url: require('../assets/scheme/two-icon.png'), title: '高效咨询', text: '缺乏专业咨询管理平台导致效率不高', text2: ' 学校咨询服务力量不足' },
        { url: require('../assets/scheme/three-icon.png'), title: '精准干预', text: '危机干预工作缺乏统一过程管理', text2: ' 危机来源缺乏系统管理与精准识别' },
        { url: require('../assets/scheme/four-icon.png'), title: '教育职能', text: '缺乏系统性的心理课程资源', text2: ' 缺乏针对性的心理健康组织活动' },
      ],
      centreTwoList: [
        { url: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/neirong1.png', title: '教师：毛伟宾', text: '单位：山东师范大学' },
        { url: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/neirong2.png', title: '教师：陈秀珍、吉家文', text: '单位：海南经贸职业技术学院' },
        { url: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/neirong3.png', title: '教师：李媛', text: '单位：电子科技大学' },
      ],
      centreThreeList: [
        { url: require('../assets/scheme/2.png'), title: '主题培训', list1: '结合当下心理健康热点开展主题培训'},
        { url: require('../assets/scheme/3.png'), title: '系列培训', list1: '结合学校心理健康教育工作者的实际需求,开展集理论实践、线上线下、基础专业于一体的系列培训'},
        { url: require('../assets/scheme/4.png'), title: '定制化研讨会', list1: '根据学校个性化需求,为学校进行定制化培训',},
        { url: require('../assets/scheme/1.png'), title:'特色工作坊',list1: '综合考虑心理健康特色内容,设计工作坊项目'},
      ],
      changjingList: [
        { url: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/changjing1.png', title: '心理测评', text: '系统提供丰富的量表库，支持设置个性化量表和预警规则，测评过程监控、预警实时可查，自动生成测评报告。' },
        { url: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/changjing2.png', title: '心理咨询', text: '提供咨询预约、线下咨询管理、线上专家咨询和自助咨询等多元化服务，帮助学校提高咨询指导服务的效率和质量' },
        { url: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/changjing3.png', title: '危机干预', text: '通过横向工作流程和纵向自动识别的网络化管理体系，多个维度识别潜在危机，实现从危机识别、上报、评估、确认到干预的全流程闭环管理和精准干预。' },
        { url: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/changjing4.png', title: '心理教育', text: '整合优质资源，包括心理课程、心理微课、心理图书和活动锦囊等内容，完整记录学生学习记录，服务学校日常教学和教改工作。' },
      ],
      productList: [
        {
          title: '团体活动室',
          src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/1.png',
          text: '可配置设备：团体活动包、游戏、素质拓展包、心理挂图及制度',
          info: '该室可用于提升人际交往能力、合作意识和个人优势开发。也可用于开展团体辅导活动，或者用于教师开展教学研讨、教师培训等活动。',
        },
        {
          title: '智能宣泄室',
          src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/2.png',
          text: '可配置设备：不倒翁人形沙袋、呐喊宣泄系统、打击宣泄系统等',
          info: '该室可用于通过各种道具和宣泄系统的应用，舒缓个人紧张情绪，让人回归平静。',
        },
        {
          title: '音乐减压室',
          src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/3.png',
          text: '可配置设备：音乐治疗自助减压系统软件、心理挂图及制度',
          info: '该室可用于音乐放松训练，消除悲观、焦虑紧张等负面情绪，改善睡眠质量。该室亦可用于辅助催眠。',
        },
        {
          title: '个体咨询室',
          src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/4.png',
          text: '可配置设备：沙发、茶几、绿植、心理图书、心理挂图及制度',
          info: '该室可用于倾吐心声、认识自我、增强来访者的心理适应能力、心理承受能力。',
        },
        {
          title: 'VR训练室',
          src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/5.png',
          text: '可配置设备：桌椅、电脑、沙发、系统软件、心理挂图及制度',
          info: '该室可用于情绪控制训练、抗挫折培训、学习压力管理。可提升幸福感、改善考试状态、缓解考试焦虑、改善人际关系。',
        },
        {
          title: '沙盘游戏室',
          src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/6.png',
          text: '可配置设备：立柜、桌子、沙盘游戏、心理挂图及制度',
          info: '该室可通过沙盘游戏的非言语支持，激发自我力量，化解内心冲突与压力。',
        },
      ],
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.topImg {
  display: flex;
  position: relative;
  margin-top: 2rem;
}
.topImg > img {
  width: 100%;
  height: 7.1rem;
}
.topImg-headline {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 1.2rem;
}
.topImg-title {
  font-size: 1.8rem;
  font-family: AlibabaPuHuiTiB;
  color: #ffffff;
}
.topImg-text {
  font-size: 1.6rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #ffffff;
}
.content-centre {
  padding: 0rem 1.2rem;
}
.centre-title {
  text-align: center;
  height: 2.5rem;
  font-size: 1.8rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  line-height: 2.5rem;
  margin: 2rem 0rem 1.6rem;
}
.centre-box {
  height: 10.05rem;
  background: #ffffff;
  box-shadow: 0rem 0.2rem 0.6rem 0rem rgba(1, 86, 255, 0.1);
  border-radius: 0.5rem;
  margin-top: 3.25rem;
}
.centre-box-text {
  padding-left: 2.7rem;
  padding-top: 3.7rem;
}

ul li::marker {
  color: #0156ff;
}
.centre-box-text ul li {
  font-size: 1.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2.4rem;
  list-style: disc;
  list-style: inside;
}
.center-one-top {
  position: relative;
}
.center-one-top-box {
  position: absolute;
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
  top: -1.25rem;
}
.center-one-top-box > img {
  width: 2.6rem;
  margin-right: 1.2rem;
}
.center-one-top-text {
  width: 9.75rem;
  height: 3.3rem;
  line-height: 3.3rem;
  background: rgba(1, 86, 255, 0.1);
  border-radius: 0.5rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0156ff;
  text-align: center;
}
.content-two {
  width: 100%;
  background: #f8faff;
}
.center-two-content {
  width: 35rem;
  margin: 0 auto;
  padding-bottom: 3rem;
}
.center-two-contentImg > img {
  width: 35rem;
}
// 平台系统架构图
h2 {
  height: 2.25rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #0156ff;
  line-height: 2.25rem;
  text-align: center;
  padding: 1.5rem 0rem 1.2rem;
}
.pingtai > img {
  width: 100%;
  height: 20.3rem;
  margin: 0 auto;
  padding-bottom: 1.6rem;
}
// 核心应用场景
.content-four {
  width: 100%;
  padding-bottom: 2.5rem;
  background: #f8faff;
}
.content-changjing {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  text-align: justify;
  box-sizing: border-box;
}
// 	/*隐藏滚动条*/
.content-changjing::-webkit-scrollbar {
  display: none;
}

.yingjian-three-box::-webkit-scrollbar {
  display: none;
}
.teachers {
  display: flex;
  overflow-x: scroll;
}
.teachers::-webkit-scrollbar {
  display: none;
}
.content-course-box {
  display: flex;
  overflow-x: scroll;
}
.content-course-box::-webkit-scrollbar {
  display: none;
}
.content-four-box {
  margin: 0 1.2rem;
  width: 25.35rem;
  background: #ffffff;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  border: 0.05rem solid #e1e1e1;
}
.changjingImg > img {
  width: 25.3rem;
  height: 11.1rem;
}
.changjing-box {
  padding: 0 1.2rem;
}
.changjing-title {
  padding-top: 2rem;
  text-align: center;
  height: 2rem;
  font-size: 1.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  line-height: 2rem;
}
.changjing-text {
  padding-top: 1.2rem;
  font-size: 1.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 1.8rem;
  padding-bottom: 2.5rem /* 40/16 */;
}
// 大学生心理健康课程资源
.content-course {
  width: 38.15rem;
  height: 18.8rem;
  background: #ffffff;
  box-shadow: 0rem 0.1rem 0.4rem 0rem rgba(1, 86, 255, 0.1);
  border-radius: 0.5rem;
  margin-right: 1.6rem;
  //   margin-bottom: 1.6rem;
  margin-bottom: 0.6rem;
  border-radius: 0.5rem;
  //   padding-bottom: 1.6rem;
  padding-bottom: 2.5rem;
}
.content-course:nth-child(3) {
  margin-right: 0rem;
}
.course {
  margin-bottom: 1.6rem;
}
.courseImg > img {
  width: 19.1rem;
}
.course-title {
  height: 2rem;
  font-size: 1.4rem !important;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  line-height: 2rem;
  padding: 0rem 1.2rem;
  margin: 1.6rem 0rem 1.2rem;
}
.course-text {
  width: 15.6rem;
  height: 1.65rem;
  font-size: 1.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 1.65rem;
  padding-top: 1.2rem;
  padding: 0rem 1.2rem;
}
// 大学生心理健康自助学习资源
.zizhuBox,
.jiansheBox,
.content-four {
  width: 100%;
  background: #f8faff;
  margin-top: 1.6rem;
}
.zizhuImg > img {
  width: 21.5rem;
  margin: 0 auto;
  margin-left: 50%;
  transform: translateX(-50%);
}
.zizhuList ul li {
  padding-bottom: 1.6rem;
  // list-style: inside;
  font-size: 1.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2rem;
}
.zizhuList ul li:last-child {
  padding-bottom: 2rem;
}

.zizhuList ul li {
  list-style: none;
  position: relative;
  padding-left: 0.5rem;
}
.zizhuList ul li::after {
  content: '';
  position: absolute;
  left: -0.5rem;
  top: 0.8rem;
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  background: #0156ff;
}

zizhuList ul li {
  list-style: none;
  position: relative;
  padding-left: 0.5rem;
}
zizhuList ul li ::after {
  content: '';
  position: absolute;
  left: -0.5rem;
  top: 0.8rem;
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  background: #0156ff;
}

// 培训  个性化师资培训方案

.teachers {
  margin-bottom: 2.8rem;
}
.teachers-box {
  margin-bottom: 2rem;
  margin-right: 1.6rem;
  width: 20.95rem;
  // height: 20.6rem;
  background: #ffffff;
  box-shadow: 0.3rem 0.35rem 0.65rem 0rem rgba(1, 86, 255, 0.09);
  border-radius: 0.5rem;
}
.teachers-box:nth-child(4) {
  margin-right: 0rem;
}
.teachersImg {
  text-align: center;
}
.teachersImg > img {
  width: 2.8rem;
  margin: 2rem 0rem 0.7rem;
}
.teachers-title {
  text-align: center;
  height: 2.25rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2.25rem;
}
.teachers-List {
  margin-top: 1.2rem;
  padding: 0rem 1.2rem;
}
.teachers-List ul li {
  list-style: disc;
  width: 18.15rem;
  font-size: 1.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2.4rem;
}
.teachers-List ul li:last-child {
  margin-bottom: 1.5rem;
}
// 心理健康教育空间环境建设方案
.product-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  margin-bottom: 2.8rem;
}
.product-bottom-img {
  margin-top: 1.6rem;
  height: 13.7rem;
  display: flex;
  position: relative;
}
.product-bottom-img > img {
  width: 16.5rem;
}
h4 {
  height: 1.4rem;
  font-size: 1rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.4rem;
  padding: 2rem 1.6rem 0.8rem;
}
.product-bottom-img-hover-text {
  height: 2rem;
  font-size: 0.7rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1rem;
  padding: 0rem 1.6rem 1rem;
}
.product-bottom-img-hover {
  width: 19.2rem;
  height: 13.7rem;
  background: #4676f8;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  animation: myMove steps(11) 0.6s forwards;
  animation: 5000s;
}
.product-bottom-img-text {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  color: #ffffff;
  height: 2.25rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 2.25rem;
}
// 预期建设效果
.jiansheBox {
  width: 100%;
  background: #f8faff;
}
.jianshe-list ul li {
  list-style: inside;
  font-size: 1.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2rem;
  padding: 0rem 1.2rem;
  padding-bottom: 0.7rem;
}
// .jianshe-list ul li{
//   list-style: none;
//   position: relative;
//   padding-left: .5rem;
// }
// .jianshe-list ul li::after{
//   content: '';
//   position: absolute;
//   // left: -.5rem;
//   top: .8rem;
//   display: block;
//   width: .5rem;
//   height: .5rem;
//   border-radius: 1rem;
//   background: #0156ff;
// }
// .jianshe-list ul li::after{
//   content: "..";
//   left: 50%;
//   position: absolute;
//   color: #0156ff;
// }
// 心灵通四统一
.yingjian-three {
  width: 15.4rem;
  // height: 21.45rem;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 0.5rem;
  margin: 0rem 1.2rem;
}
.yingjian-bj {
  background-image: url(../assets/scheme/bj.png);
  width: 100%;
  height: 27rem;
  margin-bottom: 2.8rem;
}
.yingjian-three-Img {
  text-align: center;
}
.yingjian-three-Img > img {
  width: 2.7rem;
  margin: 2rem 0rem 0.5rem;
}
.yingjian-three-title {
  height: 2.25rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 2.25rem;
  text-align: center;
  margin-bottom: 1.2rem;
}
.yingjian-three-text {
  // height: 9rem;
  font-size: 1.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.8rem;
  padding: 0rem 1.2rem;
  text-align: justify;
  padding-bottom: 1rem;
}
.yingjian-three-box {
  display: -webkit-box;
  overflow-x: scroll;
  overflow-y: hidden;
  text-align: justify;
  box-sizing: border-box;
  padding-top: 3rem;
}

/deep/.el-tabs__nav-wrap {
  overflow: hidden;
  margin-bottom: -0.05rem;
  position: relative;
}
/deep/.el-tabs__active-bar {
  margin-bottom: -1.1rem;
}
/deep/ .el-tabs__nav-scroll {
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  padding-bottom: 1.1rem;
}
/deep/.el-tabs__item {
  padding: 0 3rem;
  font-size: 1.6rem;
}

/deep/.el-tabs__nav-scroll {
  display: flex;
  justify-content: space-around;
}
/deep/.el-carousel__button {
  background: #888;
}
/deep/.el-tabs__active-bar {
  background-color: #0156ff;
}
/deep/.el-tabs__item.is-active {
  color: #0156ff;
}
/deep/.el-tabs__item:hover {
  color: #0156ff;
}
</style>