<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/scheme/top-img3.png" alt="" />
      <!-- <div class="topImg-headline">
          <div class="topImg-title">基础教育</div>
          <div class="topImg-text"> 心理危机筛查与干预解决方案</div>
        </div> -->
    </div>
    <!-- 中心内容 -->
    <div class="content-centre">
      <!-- 基础教育用户需求 -->
      <div class="center-one">
        <div class="center-one-content">
          <h1>基础教育用户需求</h1>
          <div style="display: flex; justify-content: space-between">
            <div class="centerImg">
              <img src="../assets/scheme/basics-one.png" alt="" />
              <p class="center-one-content-title">心理测评</p>
            </div>
            <div class="centerImg">
              <img src="../assets/scheme/basics-two.png" alt="" />
              <p class="center-one-content-title">心理干预</p>
            </div>
            <div class="centerImg">
              <img src="../assets/scheme/basics-three.png" alt="" />
              <p class="center-one-content-title">教师培训</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center-two">
      <div class="center-two-content">
        <h1>心理危机筛查与干预总体工作流程</h1>
        <div class="center-two-contentImg">
          <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/fangan-jichu.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 心理危机筛查结果分类处理规范 -->
    <!-- <div class="center-three">
      <div class="center-one-content">
        <h1>心理危机筛查结果分类处理规范</h1>
        <div class="center-three-contentImg">
          <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/five.png" alt="" />
        </div>
      </div>
    </div> -->
    <div class="center-three-content">
      <h1>解决方案交付内容</h1>
      <div class="elTabs">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="心理测评工作平台" name="first">
            <div class="pingtai">
              <p class="center-four-first-title">通过PC/小程序实现场景化的心理普测和单独测试，支持多次重复测试</p>
              <div style="display: flex; justify-content: space-between; margin-top: 1.2rem; margin-bottom: 3rem; overflow-x: scroll">
                <div class="center-fourImg">
                  <div style="display: flex; algin-items: center; justify-content: space-evenly; padding-bottom: 0.5rem">
                    <div class="center-four-btn">知情同意书</div>
                    <div class="center-four-icon"><img src="../assets/scheme/center-four-one.png" alt="" /></div>
                  </div>
                  <img src="../assets/scheme/jichu-one-first.png" alt="" />
                </div>
                <div class="center-fourImg">
                  <div style="display: flex; algin-items: center; justify-content: space-evenly; padding-bottom: 0.5rem">
                    <div class="center-four-btn">指导语和基本信息</div>
                    <div class="center-four-icon"><img src="../assets/scheme/center-four-one.png" alt="" /></div>
                  </div>
                  <img src="../assets/scheme/jichu-one-second.png" alt="" />
                </div>
                <div class="center-fourImg">
                  <div style="display: flex; algin-items: center; justify-content: space-evenly; padding-bottom: 0.5rem">
                    <div class="center-four-btn">回答问题</div>
                    <div class="center-four-icon"><img src="../assets/scheme/center-four-one.png" alt="" /></div>
                  </div>
                  <img src="../assets/scheme/jichu-one-third.png" alt="" />
                </div>
                <div class="center-fourImg">
                  <div style="display: flex; algin-items: center; justify-content: space-evenly; padding-bottom: 0.5rem">
                    <div class="center-four-btn">完成提交</div>
                  </div>
                  <img src="../assets/scheme/jichu-one-fourth.png" alt="" />
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="丰富的心理测评量表库" name="second">
            <div class="liangbiao">
              <p class="center-four-first-title">量表引入国内外权限专家开发并经过行业实践验证的通用量表，包括心理健康筛查类和心理健康发展类，量表总数不少于50个。</p>
              <div class="liangbiaoImg"><img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/fangan-jichu2.png" alt="" /></div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="针对性的教师培训体系" name="third">
            <div class="peixun">
              <p class="center-four-first-title">根据高等教育、中等职业教育和基础教育的特点，针对管理部门、咨询师、课程授课教师、辅导员等角色提供专业的培训课程体系， 激活教师团队，提升本土化的心理危机识别与干预能力；培训分为线上培训和线下培训两种模式，支持为学校定制个性化培训方案。</p>
              <div class="peixun-box">
                <div class="teacher-left-box">
                  <div class="teacher-left-boxImg"><img src="../assets/scheme/teacher-left-icon.png" alt="" /></div>
                  <p class="teacher-title">基本素养培训</p>
                  <div class="teacher-list">
                    <ul>
                      <li>心理危机筛查与干预相关政策</li>
                      <li>基础教育常见心理问题及处理办法</li>
                      <li>心理危机筛查和干预过程中的注意事项</li>
                    </ul>
                  </div>
                </div>
                <div class="teacher-left-box">
                  <div class="teacher-left-boxImg"><img src="../assets/scheme/teacher-right-icon.png" alt="" /></div>
                  <p class="teacher-title">风险筛查技能培养</p>
                  <div class="teacher-list">
                    <ul>
                      <li>基础教育心理危机筛查与干预主要流程和工作内容</li>
                      <li>学生一对一访谈和风险识别培训</li>
                      <li>心理咨询常见问题及方法</li>
                    </ul>
                  </div>
                </div>
                <div class="teacher-left-box">
                  <div class="teacher-left-boxImg"><img src="../assets/scheme/teacher-left-icon.png" alt="" /></div>
                  <p class="teacher-title">管理和技术培训</p>
                  <div class="teacher-list">
                    <ul>
                      <li>心理危机筛查与干预整体流程培训</li>
                      <li>心理危机筛查与干预管理员培训</li>
                      <li>心理测评培训</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="全流程的服务支撑体系" name="fourth">
            <div class="fuwu">
              <p class="center-four-first-title">通过应用培训、线下支持、电话答疑、普测报告等方式帮助教育局解决在普测过程中的一系列技术与应用问题。</p>
              <div>
                <div class="yingjian-bj">
                  <div class="yingjian-three-box">
                    <div class="yingjian-three">
                      <div class="yingjian-three-Img"><img src="../assets/scheme/jichu-one.png" alt="" /></div>
                      <p class="yingjian-three-title">应用培训</p>
                      <p class="yingjian-three-text">通过线上、线下等方式对管理员、教师和学生进行培训，提供产品操作视频、应用视频等资源。</p>
                    </div>
                    <div class="yingjian-three">
                      <div class="yingjian-three-Img"><img src="../assets/scheme/jichu-two.png" alt="" /></div>
                      <p class="yingjian-three-title">平台运维</p>
                      <p class="yingjian-three-text">通过SaaS方式提供服务，平台、硬件、资源服务化，学校投入少，负担小。平台功能、性能实时优化，保障用户使用体验。</p>
                    </div>
                    <div class="yingjian-three">
                      <div class="yingjian-three-Img"><img src="../assets/scheme/jichu-three.png" alt="" /></div>
                      <p class="yingjian-three-title">技术支持</p>
                      <p class="yingjian-three-text">通过线上线下两种方式提供覆盖全流程的技术支持服务，解决管理员、教师和学生在使用中的问题，保障测评工作的顺利开展。</p>
                    </div>
                    <div class="yingjian-three">
                      <div class="yingjian-three-Img"><img src="../assets/scheme/jichu-four.png" alt="" /></div>
                      <p class="yingjian-three-title">分析报告</p>
                      <p class="yingjian-three-text">对普测进行全方位、多维度的数据分析，提供专业的分析报告，便于教育局和学校总结问题，进行针对性优化，建立测评优化长效机制。</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 心理危机筛查与干预长效机制 -->
    <div class="content-centre">
      <div class="center-five">
        <h1>心理危机筛查与干预长效机制</h1>
        <div>
          <el-row class="tac">
            <el-col :span="12">
              <el-menu default-active="1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" background-color ref="menu">
                <!-- 提高本地教师筛查能力 -->
                <el-submenu index="1">
                  <template slot="title">
                    <img src="../assets/scheme/jichu-fiveContent-one.png" alt="" />
                    <h3 style="padding-left: 0.7rem">提高本地教师筛查能力</h3>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="1-1"
                      ><div class="yingjian-content">
                        <p class="yingjian-text">在充分整合全国优秀咨询师和本地咨询师的基础之上，需要加强对本地教师心理危机筛查培训，提高本地教师对普测结果进行初步筛查的能力，形成本地独立筛查工作能力</p>
                      </div></el-menu-item
                    >
                  </el-menu-item-group>
                </el-submenu>
                <!-- 形成优化迭代机制 -->
                <el-submenu index="2">
                  <template slot="title">
                    <img src="../assets/scheme/jichu-fiveContent-two.png" alt="" />
                    <h3 style="padding-left: 0.7rem">形成优化迭代机制</h3>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="1-1">
                      <div class="yingjian-content">
                        <p class="yingjian-text">在普测工作常态化的前提下，重视对测评结果的分析利用，形成专业报告，分析普测中存在的问题，形成下次心理测评的优化迭代建议，逐步形成测评良性闭环优化迭代机制</p>
                      </div>
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
                <!-- 强化心理健康教育 -->
                <el-submenu index="3">
                  <template slot="title">
                    <img src="../assets/scheme/jichu-fiveContent-three.png" alt="" />
                    <h3 style="padding-left: 0.7rem">强化心理健康教育</h3>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="1-1">
                      <div class="yingjian-content">
                        <p class="yingjian-text">提供丰富的、针对性的心理健康教育资源，加强心理健康教育，主动构建心理健康防护网，降低地区心理危机问题检出率</p>
                      </div>
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
              </el-menu>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      c1: ['c1'],
      index: '1',
      isCollapse: true,
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
  },
  mounted() {
    // let menuIndex = this.$route.params.index || '1';
    // console.log(menuIndex)
    // this.$refs.menu.open(menuIndex)
    let menuIndex = '1'
    this.$refs.menu.open(menuIndex)
  },
}
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.topImg {
  display: flex;
  position: relative;
  margin-top: 2rem;
}
.topImg > img {
  width: 100%;
  height: 7.1rem;
}
.topImg-headline {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 1.2rem;
}
.topImg-title {
  font-size: 1.8rem;
  color: #ffffff;
}
.topImg-text {
  font-size: 1.6rem;
  color: #ffffff;
}
.content-centre {
  padding: 0rem 1.2rem;
}
// 基础教育用户需求
.center-one-content {
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 3.7rem;
}
h1 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #3b3d41;
  letter-spacing: 0.3rem;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2.5rem;
}
.centerImg > img {
  width: 10.9rem;
  height: 8.6rem;
}
.centerImg {
  position: relative;
}
.center-one-content-title {
  white-space: nowrap;
  font-size: 1.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center-two-content {
  width: 100%;
  background: #f8faff;
}
.center-two-contentImg,
.center-three-contentImg,
.center-four {
  padding: 0rem 1.2rem;
}
.center-two-contentImg > img,
.center-three-contentImg > img {
  width: 35.1rem;
  height: 21rem;
  padding-bottom: 2rem;
}
// 
/deep/.el-tabs__nav {
  overflow-x: scroll !important;
}
.center-three-content {
  background: #f8faff;
}
// 心理测评工作平台
.pingtai {
  width: 35rem;
}
.center-four-first-title {
  width: 35rem;
  font-size: 1.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  padding-bottom: 1.6rem;
  text-align: justify;
}
.center-four-btn {
  width: 10.7rem;
  height: 2.85rem;
  line-height: 2.85rem;
  background: linear-gradient(270deg, #4080ff 0%, #0156ff 100%);
  border-radius: 0.5rem;
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  margin-left: 2.25rem;
}
.center-fourImg > img {
  width: 16.1rem;
  height: 25.3rem;
}
.center-four-icon {
  display: flex;
  align-items: center;
}
// 个性化的测评量表体系
.liangbiaoImg > img {
  width: 35.1rem;
  height: 17.5rem;
}
.liangbiao {
  width: 35rem;
}
// 针对性的教师培训体系
.peixun-box {
  display: -webkit-box;
  overflow-x: scroll;
  width: 100%;
}
.peixun-box::-webkit-scrollbar {
  display: none;
}
.teacher-left-box {
  width: 16rem;
  // height: 21.55rem;
  background: #ffffff;
  box-shadow: 0.3rem 0.35rem 0.65rem 0rem rgba(1, 86, 255, 0.09);
  border-radius: 0.5rem;
  margin-right: 1.2rem;
  margin-bottom: 1.6rem;
  margin-top: 1.6rem;
}
.teacher-left-box:last-child {
  margin-right: 0rem;
}
.teacher-left-boxImg {
  text-align: center;
}
.teacher-left-boxImg > img {
  width: 2.6rem;
  margin-top: 2rem;
}
.teacher-title {
  height: 2.25rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2.25rem;
  margin: 0.7rem 0rem 1.2rem;
  text-align: center;
}
.teacher-list {
  padding: 0rem 1.2rem;
}
.teacher-list ul li {
  font-size: 1.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 1.8rem;
  padding-left: 0.5rem;
  // list-style: disc;
}
// .teacher-list ul li::marker{
//     color: #0156FF ;
// }
.teacher-list ul {
  padding-bottom: 1rem;
}
.teacher-list ul li {
  list-style: none;
  position: relative;
}
.teacher-list ul li::after {
  content: '';
  position: absolute;
  left: -0.5rem;
  top: 0.8rem;
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 1rem;
  background: #0156ff;
}

// 全流程的服务支撑体系
.yingjian-three {
  width: 15.4rem;
  // height: 21.45rem;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 0.5rem;
  margin: 0rem 1.2rem;
}
.yingjian-bj {
  background-image: url(../assets/scheme/bj2.png);
  width: 100%;
  height: 27rem;
  // margin-bottom: 2.8rem;
  padding-bottom: 2.8rem;
}
.yingjian-three-Img {
  text-align: center;
}
.yingjian-three-Img > img {
  width: 2.7rem;
  margin: 2rem 0rem 0.5rem;
}
.yingjian-three-title {
  height: 2.25rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 2.25rem;
  text-align: center;
  margin-bottom: 1.2rem;
}
.yingjian-three-text {
  // height: 9rem;
  font-size: 1.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.8rem;
  padding: 0rem 1.2rem;
  text-align: justify;
  padding-bottom: 1rem;
}
.yingjian-three-box {
  display: -webkit-box;
  overflow-x: scroll;
  overflow-y: hidden;
  text-align: justify;
  box-sizing: border-box;
  padding-top: 3rem;
}

// 心理危机筛查与干预长效机制
.center-five {
  margin-bottom: 2.8rem;
}
.yingjian-content {
  background: #f8faff;
  height: 100%;
}
h3 {
  display: inline-block;
  height: 2.25rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2.25rem;
}
.yingjian-text {
  width: 31.9rem;
  padding-top: 1.6rem;
  font-size: 1.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2.4rem;
}
/deep/.el-submenu__title {
  width: 35.1rem;
  height: 4.85rem;
  line-height: 4.85rem;
  border: 0.1rem solid #e1e1e1;
}
/deep/.el-submenu__title > img {
  padding-left: 1.2rem;
  width: 2.6rem;
}
/deep/ .el-menu-item {
  white-space: normal;
  width: 35.1rem;
  height: 13rem !important;
  vertical-align: baseline;
}
/deep/.el-col-12 {
  width: 100%;
}
/deep/.el-menu-item-group > ul {
  background: #f8faff;
  padding: 0rem 1rem;
  border: 0.1rem solid #e1e1e1;
}
/deep/.elTabs ::-webkit-scrollbar {
  display: none;
}
/deep/.el-tab-pane {
  padding: 0 1.2rem;
}
/deep/.el-tabs__nav-wrap {
  overflow: hidden;
  margin-bottom: -0.05rem;
  position: relative;
}
/deep/ .el-tabs__nav-scroll {
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
}
/deep/.el-tabs__nav {
  padding: 1rem 1.2rem;
}
/deep/.el-tabs__nav-scroll {
  display: flex;
  justify-content: space-around;
}
/deep/.el-carousel__button {
  background: #888;
}
/deep/.el-tabs__active-bar {
  background-color: #0156ff;
  z-index: 0;
  margin-bottom: 0rem;
  margin-left: 1.2rem;
  // width: 2.5rem !important;
}
/deep/.el-tabs__item {
  font-size: 1.6rem;
}
/deep/.el-tabs__item.is-active {
  color: #0156ff;
}
/deep/.el-tabs__item:hover {
  color: #0156ff;
}
/deep/.el-menu {
  width: 100%;
}
/deep/.el-submenu__title:hover {
  background-color: white;
}
/deep/.el-submenu__icon-arrow {
  top: 50%;
}
</style>