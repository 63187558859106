<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/scheme/top-img2.png" alt="" />
      <!-- <div class="topImg-headline">
        <div class="topImg-title">中等职业教育</div>
        <div class="topImg-text">心理危机筛查与干预解决方案</div>
      </div> -->
    </div>
    <!-- 中心内容 -->
    <div class="content-centre">
      <!-- 应用方案 -->
      <div class="center-one">
        <h1>应用方案</h1>
        <div>
          <!-- <div class="block">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                :timestamp="activity.timestamp">
               
                {{activity.timestamp}}
                {{activity.text}}
          
              </el-timeline-item>
            </el-timeline>
          </div> -->
          <el-timeline :class="{ 'el-timeline-hidden': timelineShow }">
            <el-timeline-item timestamp="2016" placement="top" color="#0156FF">
              <div class="card-box">
                <p class="card-time">8月19日</p>
                <p class="card-text">全国卫生与健康大会召开。习近平总书记出席会议并发表讲话。要加大心理健康问题基础性研究，做好心理健康知识和心理疾病科普工作，规范发展心理治疗、心理咨询等心理健康服务。</p>
              </div>
              <div class="card-box">
                <p class="card-time">12月30日</p>
                <p class="card-text">国家卫生计生委、中宣部、中央综治办、民政部等22个部门共同印发《关于加强心理健康服务的指导意见》，是我国首个加强心理健康服务的宏观指导性意见。</p>
              </div>
            </el-timeline-item>
            <el-timeline-item timestamp="2018" placement="top" color="#0156FF">
              <div class="card-box">
                <p class="card-time">11月29日</p>
                <p class="card-text">国家卫生健康委等10部委联合发布《关于印发全国社会心理服务体系建设试点工作方案的通知》，推动社会心 理服务体系建设。</p>
              </div>
            </el-timeline-item>
            <el-timeline-item timestamp="2021" placement="top" color="#0156FF">
              <div class="card-box">
                <p class="card-time">7月12日</p>
                <div class="card-text">
                  <p>教育部办公厅关于加强学生心理健康管理工作的通知，明确提出如下要求：</p>
                  <p>1、做好心理健康测评工作</p>
                  <p>2、强化日常预警防控</p>
                  <p>3、加强心理咨询辅导服务</p>
                  <p>4、积极争取专业机构协作支持</p>
                </div>
              </div>
              <div class="card-box">
                <p class="card-time">9月3日</p>
                <p class="card-text">教育部等五部门发布《关于全面加强和改进新时代学校卫生与健康教育工作的意见》提出健康第一，以中小学为重点， 完善以课堂教学为主渠道、主题教育为重要载体、以日常教育为基础的健康教育推进机制，每学期应在体育与健康课程总课时中安排4个健康教育课时。</p>
              </div>
              <div class="card-box">
                <p class="card-time">11月13日</p>
                <p class="card-text">教育部发布了《关于政协第十三届全国委员会第四次会议第3839号（教育类344号）提案答复的函》，明确提出：将抑郁症筛查纳入学生健康体检内容， 建立学生心理健康档案，评估学生心理健康状况，对测评结果异常的学生给予重点关注。</p>
              </div>
            </el-timeline-item>
            <el-timeline-item></el-timeline-item>
          </el-timeline>
          <!-- v-if="timelineShow" -->
          <div class="seeMore-pull" v-if="timelineShow == true">
            <p @click="timelineShow = false" class="seeMore-pullText">了解更多</p>
            <div class="seeMore-pull-img" @click="timelineShow = false"><img src="../assets/scheme/pull.png" alt="" /></div>
          </div>
          <div class="seeMore-pull" v-if="timelineShow == false">
            <p @click="timelineShow = true" class="seeMore-pullText">收起</p>
            <div class="seeMore-pull-img" @click="timelineShow = true"><img src="../assets/scheme/up.png" alt="" /></div>
          </div>
        </div>
      </div>
    </div>
    <div class="center-two">
      <h1>中等职业教育心理健康解决方案</h1>
      <div class="content-centre">
        <div class="centre-two">
          <div class="centre-two-box">
            <div class="zhongdeng-two">
              <div class="zhongdengImg">
                <img src="../assets/scheme/zhongdeng-one-icon.png" alt="" />
              </div>
              <p class="centre-two-title">心理测评</p>
              <div class="bottom-line"></div>
              <div class="zhongdeng-list">
                <ul>
                  <li>丰富的量表库</li>
                  <li>定制化多维画像测评量表</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="centre-two-box">
            <div class="zhongdeng-two">
              <div class="zhongdengImg">
                <img src="../assets/scheme/zhongdeng-two-icon.png" alt="" />
              </div>
              <p class="centre-two-title">风险预警</p>
              <div class="bottom-line"></div>
              <div class="zhongdeng-list">
                <ul>
                  <li>实时预警，及时关注测评异常学生</li>
                  <li>定制化测评报告</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="centre-two-box">
            <div class="zhongdeng-two">
              <div class="zhongdengImg">
                <img src="../assets/scheme/zhongdeng-three-icon.png" alt="" />
              </div>
              <p class="centre-two-title">学生访谈</p>
              <hr />
              <div class="zhongdeng-list">
                <ul>
                  <li>针对异常学生进行访谈， 确定心理问题</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="centre-two-box">
            <div class="zhongdeng-two">
              <div class="zhongdengImg">
                <img src="../assets/scheme/zhongdeng-four-icon.png" alt="" />
              </div>
              <p class="centre-two-title">师资培训</p>
              <hr />
              <div class="zhongdeng-list">
                <ul>
                  <li>对本地教师和咨询师进行系统性培训</li>
                  <li>赋能教师，提高专业能力</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="centre-two-box">
            <div class="zhongdeng-two">
              <div class="zhongdengImg">
                <img src="../assets/scheme/zhongdeng-five-icon.png" alt="" />
              </div>
              <p class="centre-two-title">心理档案库</p>
              <hr />
              <div class="zhongdeng-list">
                <ul>
                  <li>整合数据，生成学生心理测评档案，画像清晰，咨询意见完善</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-centre">
      <div class="elTabs">
        <el-tabs v-model="activeName">
          <el-tab-pane label="心理测评工作平台" name="first">
            <div class="pingtai">
              <p class="center-four-first-title">通过小程序实现场景化的心理普测和单独测试，支持断点保存测试</p>
              <div style="display: flex; justify-content: space-between; margin-bottom: 1.15rem">
                <div class="center-fourImg">
                  <img src="../assets/scheme/pingtai-one.png" alt="" />
                </div>
                <div class="center-fourImg">
                  <img src="../assets/scheme/pingtai-two.png" alt="" />
                </div>
                <div class="center-fourImg">
                  <img src="../assets/scheme/pingtai-three.png" alt="" />
                </div>
              </div>
              <div style="display: flex; justify-content: space-evenly; align-items: center; padding-bottom: 1.6rem">
                <div class="center-four-btn">知情同意书</div>
                <div class="center-four-icon">
                  <img src="../assets/scheme/center-four-one.png" alt="" />
                </div>
                <div class="center-four-btn">开始测评</div>
                <div class="center-four-icon">
                  <img src="../assets/scheme/center-four-one.png" alt="" />
                </div>
                <div class="center-four-btn">提交完成</div>
              </div>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="多样化测评量表" name="second">
            <div class="liangbiao">
              <div class="liangbiaoImg">
                <div style="margin-top: 1.6rem">
                  <p class="center-three-title">平台量表分为两种：</p>
                  <p class="center-three-text">1.结合中等职业学生特点，提供丰富的、多样化的通用型量表，数量不低于100个。</p>
                  <p class="center-three-text">2.个性化量表：针对中等职业教育特点设计的极端心理、校园霸凌、人际关系、学习困难四大画像量表，包含42个子画像维度，量表基于国际通用量表，根据本土化测试特点进行设计而成，同时支持个性化量表，下表为部分关键子画像维度说明。</p>
                  <div style="display: flex; justify-content: space-around; margin: 1.6rem 0rem 1.6rem; margin-bottom: 1.6rem">
                    <div class="duoyanghua-left">
                      <img src="../assets/scheme/trade-three-left.png" alt="" />
                      <p style="margin-top: 3.2rem">通用型量表库</p>
                    </div>
                    <div class="duoyanghua-right">
                      <img src="../assets/scheme/trade-three-right.png" alt="" />
                      <p>个性化量表</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane> -->
          <el-tab-pane label="全方位心理健康教育" name="third">
            <div>
              <p class="center-four-first-title">根据中等职业教育心理危机筛查与干预的特点，以课程、微视频、图书等形式满足老师教育教学和心理健康教育的需求，支持学生
                通过移动端进行学习。</p>
              <div style="display: flex; justify-content: space-around; margin: 1.6rem 0rem 1.6rem">
                <div class="duoyanghua-left">
                  <img style="width: 150px;" src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/pc2.png" alt="" />
                  <p style="margin-top: 3.2rem">教学辅助</p>
                </div>
                <div class="duoyanghua-right">
                  <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/mo2.png" alt="" style="width: 9.4rem" />
                  <p>移动端学习</p>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="个性化师资培训" name="fourth">
            <div class="peixun">
              <p class="center-four-first-title">根据高等教育、中等职业教育和基础教育的特点，针对管理部门、咨询师、课程授课教师、辅导员等角色提供专业的培训课程体系， 激活教师团队，提升本土化的心理危机识别与干预能力；培训分为线上培训和线下培训两种模式，支持为学校定制个性化培训方案。</p>
              <div class="peixun-box">
                <div class="teacher-left-box">
                  <div class="teacher-left-boxImg">
                    <img src="../assets/scheme/teacher-left-icon.png" alt="" />
                  </div>
                  <p class="teacher-title">基本素养培训</p>
                  <div class="teacher-list">
                    <ul>
                      <li>心理危机筛查与干预相关政策</li>
                      <li>基础教育常见心理问题及处理办法</li>
                      <li>心理危机筛查和干预过程中的注意事项</li>
                    </ul>
                  </div>
                </div>
                <div class="teacher-left-box">
                  <div class="teacher-left-boxImg">
                    <img src="../assets/scheme/teacher-right-icon.png" alt="" />
                  </div>
                  <p class="teacher-title">风险筛查技能培养</p>
                  <div class="teacher-list">
                    <ul>
                      <li>基础教育心理危机筛查与干预主要流程和工作内容</li>
                      <li>学生一对一访谈和风险识别培训</li>
                      <li>心理咨询常见问题及方法</li>
                    </ul>
                  </div>
                </div>
                <div class="teacher-left-box">
                  <div class="teacher-left-boxImg">
                    <img src="../assets/scheme/teacher-left-icon.png" alt="" />
                  </div>
                  <p class="teacher-title">管理和技术培训</p>
                  <div class="teacher-list">
                    <ul>
                      <li>心理危机筛查与干预整体流程培训</li>
                      <li>心理危机筛查与干预管理员培训</li>
                      <li>心理测评培训</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="全流程技术支撑" name="fifth">
            <div class="fuwu">
              <p class="center-four-first-title">通过应用培训、线下支持、电话答疑、普测报告等方式帮助教育局解决在普测过程中的一系列技术与应用问题。</p>
              <div>
                <div class="yingjian-bj">
                  <div class="yingjian-three-box">
                    <div class="yingjian-three">
                      <div class="yingjian-three-Img">
                        <img src="../assets/scheme/jichu-one.png" alt="" />
                      </div>
                      <p class="yingjian-three-title">应用培训</p>
                      <p class="yingjian-three-text">通过线上、线下等方式对管理员、教师和学生进行培训，提供产品操作视频、应用视频等资源。</p>
                    </div>
                    <div class="yingjian-three">
                      <div class="yingjian-three-Img">
                        <img src="../assets/scheme/jichu-two.png" alt="" />
                      </div>
                      <p class="yingjian-three-title">平台运维</p>
                      <p class="yingjian-three-text">通过SaaS方式提供服务，平台、硬件、资源服务化，学校投入少，负担小。平台功能、性能实时优化，保障用户试用体验。</p>
                    </div>
                    <div class="yingjian-three">
                      <div class="yingjian-three-Img">
                        <img src="../assets/scheme/jichu-three.png" alt="" />
                      </div>
                      <p class="yingjian-three-title">技术支持</p>
                      <p class="yingjian-three-text">通过线上线下两种方式提供覆盖全流程的技术支持服务，解决管理员、教师和学生在使用中的问题，保障测评工作的顺利开展。</p>
                    </div>
                    <div class="yingjian-three">
                      <div class="yingjian-three-Img">
                        <img src="../assets/scheme/jichu-four.png" alt="" />
                      </div>
                      <p class="yingjian-three-title">分析报告</p>
                      <p class="yingjian-three-text">对普测进行全方位、多维度的数据分析，提供专业的分析报告，便于教育局和学校总结问题，进行针对性优化，建立测评优化长效机制。</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 应用模式 -->
    <div class="center-two">
      <div>
        <h1>应用模式</h1>
        <p class="center-four-text">通知学生在规定时间范围内完成心理普查，常见有两种模式：自由式测评和集中测评</p>
        <div class="center-four-img"><img src="../assets/scheme/yingyong.png" alt="" /></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: 'first',
      zhongdengList: [
        { url: require('../assets/scheme/zhongdeng-one-icon.png'), title: '心理测评', text1: '丰富的量表库', text2: '定制化多维画像测评量表' },
        { url: require('../assets/scheme/zhongdeng-two-icon.png'), title: '风险预警', text1: '实时预警，及时关注测评异常学生', text2: '定制化测评报告' },
        { url: require('../assets/scheme/zhongdeng-three-icon.png'), title: '学生访谈', text1: '针对异常学生进行访谈， 确定心理问题' },
        { url: require('../assets/scheme/zhongdeng-four-icon.png'), title: '师资培训', text1: '对本地教师和咨询师进行系统性培训 ', text2: '赋能教师，提高专业能力' },
        { url: require('../assets/scheme/zhongdeng-five-icon.png'), title: '心理档案库', text1: '整合数据，生成学生心理测评档案，画像清晰，咨询意见完善' },
      ],
      activities: [
        {
          content: '2016',
          timestamp: '8月19日',
          text: '全国卫生与健康大会召开。习近平总书记出席会议并发表讲话。要加大心理健康问题基础性研究，做好心理健康知识和心理疾病科普工作，规范发展心理治疗、心理咨询等心理健康服务。',
        },
        {
          timestamp: '12月30日',
          text: '国家卫生计生委、中宣部、中央综治办、民政部等22个部门共同印发《关于加强心理健康服务的指导意见》，是我国首个加强心理健康服务的宏观指导性意见。',
        },
        {
          content: '2018',
          timestamp: '2018-04-11',
          text: '国家卫生健康委等10部委联合发布《关于印发全国社会心理服务体系建设试点工作方案的通知》，推动社会心 理服务体系建设。',
        },
      ],
      arr: [
        {
          timestampYear: '2016',
          children: [
            {
              timestamp: '8月19日',
              text: '全国卫生与健康大会召开。习近平总书记出席会议并发表讲话。要加大心理健康问题基础性研究，做好心理健康知识和心理疾病科普工作，规范发展心理治疗、心理咨询等心理健康服务。',
            },
            {
              timestamp: '9月19日',
              text: '全国卫生与健康大会召开。习近平总书记出席会议并发表讲话。要加大心理健康问题基础性研究，做好心理健康知识和心理疾病科普工作，规范发展心理治疗、心理咨询等心理健康服务。',
            },
          ],
        },
        {
          timestampYear: '2017',
          children: [
            {
              timestamp: '8月19日',
              text: '全国卫生与健康大会召开。习近平总书记出席会议并发表讲话。要加大心理健康问题基础性研究，做好心理健康知识和心理疾病科普工作，规范发展心理治疗、心理咨询等心理健康服务。',
            },
            {
              timestamp: '9月19日',
              text: '全国卫生与健康大会召开。习近平总书记出席会议并发表讲话。要加大心理健康问题基础性研究，做好心理健康知识和心理疾病科普工作，规范发展心理治疗、心理咨询等心理健康服务。',
            },
          ],
        },
      ],
      timelineShow: true,
    }
  },
  methods: {},
}
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.topImg {
  display: flex;
  position: relative;
  margin-top: 2rem;
}
.topImg > img {
  width: 100%;
  height: 7.1rem;
}
.topImg-headline {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 1.2rem;
}
.topImg-title {
  font-size: 1.8rem;
  font-family: AlibabaPuHuiTiB;
  color: #ffffff;
}
.topImg-text {
  font-size: 1.6rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #ffffff;
}
.content-centre {
  padding: 0rem 1.2rem;
}
h1 {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2.5rem;
  color: #3b3d41;
  font-size: 1.8rem;
}
// 应用方案
.van-step--vertical {
  font-size: 1.4rem;
}
.van-step--vertical {
  line-height: inherit;
}
/deep/.el-timeline-item__content {
  font-size: 1.4rem;
}
/deep/.el-timeline-item__timestamp {
  // margin-bottom: 1.6rem;
  font-size: 1.4rem;
  color: #3b3d41;
  font-weight: 600;
}
.card-box {
  display: flex;
  margin-top: 1.2rem;
}
.card-time {
  width: 5rem;
  white-space: nowrap;
}
.card-text {
  font-size: 1.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2.4rem;
  margin-left: 1rem;
  text-align: justify;
}
.el-timeline {
  // height: 24rem;
  overflow: hidden;
}
/deep/.el-timeline-item__wrapper {
  padding-left: 1.4rem;
}
// 查看更多案例
.seeMore-pull {
  display: flex;
  align-items: center;
  width: 10rem /* 100/16 */;
  margin: 0 auto;
  margin-bottom: 2.5rem /* 40/16 */;
}
.seeMore-pullText {
  font-size: 2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0156ff;
  line-height: 2.8125rem;
}
.seeMore-pull-img {
  display: flex;
  padding-top: 0.5rem;
}
.seeMore-pull-img > img {
  width: 1.4rem;
  padding-left: 0.5rem;
}
/deep/.el-timeline-item__node--normal {
  left: 0.2rem;
  width: 0.6rem;
  height: 0.6rem;
}

.center-two {
  width: 100%;
  background: #f8faff;
}
.centre-two {
  width: 100%;
  padding-bottom: 2rem;
  overflow-x: scroll;
  padding-bottom: 2rem;
  display: -webkit-inline-box;
}
.centre-two::-webkit-scrollbar {
  display: none;
}
.centre-two-box {
  width: 16rem;
  height: 18rem;
  background: #ffffff;
  border: 0.05rem solid #e1e1e1;
  margin-right: 1.6rem;
}
.zhongdeng-two {
  padding: 0rem 1.2rem;
}
.centre-two-box:last-child {
  margin-right: 0rem;
}
.zhongdengImg {
  text-align: center;
}
.zhongdengImg > img {
  width: 3rem;
  margin: 1.3rem 0rem 0.9rem;
}
.centre-two-title {
  height: 2.25rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2.25rem;
  text-align: center;
  margin-bottom: 0.85rem;
}
.zhongdeng-list {
  margin-top: 1.6rem;
}
// .zhongdeng-list ul {
//   list-style: inside;
// }

.zhongdeng-list ul li {
  list-style: none;
  position: relative;
  padding-left: 0.5rem;
}
.zhongdeng-list ul li::after {
  content: '';
  position: absolute;
  left: -0.5rem;
  top: 0.8rem;
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 1rem;
  background: #0156ff;
}

.zhongdeng-list ul li {
  font-size: 1.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 1.8rem;
}
.zhongdeng-list ul li::marker {
  color: #0156ff;
}
.bottom-line {
  width: 100%;
  border: 0.0625rem solid #e1e1e1;
}
.pingtai {
  width: 35rem;
  overflow-x: scroll;
}
.center-four-first-title {
  width: 35rem;
  font-size: 1.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  padding-bottom: 1.8rem;
  padding-top: 1.6rem;
}
.center-four-btn {
  width: 10.7rem;
  height: 2.85rem;
  line-height: 2.85rem;
  border-radius: 0.5rem;
  color: #3b3d41;
  font-size: 1.4rem;
  text-align: center;
}
/deep/.el-tabs__nav-wrap.is-scrollable {
  padding: 0rem 0rem;
}
// .center-four-icon>img{
//   width: 2rem;
//   height: 1.2rem;
// }
.center-fourImg > img {
  width: 10.8rem;
  height: 22.2rem;
}

// 多样化测评量表
.duoyanghua-left > img {
  width: 22.2rem;
  height: 12rem;
}
.duoyanghua-left > p,
.duoyanghua-right > p {
  text-align: center;
  margin-top: 4.7rem;
  font-size: 1.4rem;
  position: absolute;
  bottom: 0;
}
.duoyanghua-right,
.duoyanghua-left {
  position: relative;
  padding-bottom: 1.5rem;
}
// s
.duoyanghua-left > p,
.duoyanghua-right > p {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  //   bottom: -1.3rem;
}
.duoyanghua-right > img {
  width: 13.2rem;
  height: 15.2rem;
}
.center-three-title,
.center-three-text {
  font-size: 1.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2rem;
}

// 个性化师资培训
.peixun-box {
  display: -webkit-box;
  overflow-x: scroll;
  width: 100%;
}
.peixun-box::-webkit-scrollbar {
  display: none;
}
.teacher-left-box {
  width: 16rem;
  background: #ffffff;
  box-shadow: 0.3rem 0.35rem 0.65rem 0rem rgba(1, 86, 255, 0.09);
  border-radius: 0.5rem;
  margin-right: 1.2rem;
  margin-bottom: 1.6rem;
  margin-top: 1.6rem;
}
.teacher-left-box:last-child {
  margin-right: 0rem;
}
.teacher-left-boxImg {
  text-align: center;
}
.teacher-left-boxImg > img {
  width: 2.6rem;
  margin-top: 2rem;
}
.teacher-title {
  height: 2.25rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 2.25rem;
  margin: 0.7rem 0rem 1.2rem;
  text-align: center;
}
.teacher-list {
  padding: 0rem 1.2rem;
}
.teacher-list ul li {
  font-size: 1.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 1.8rem;
  // list-style: disc;
}
.teacher-list ul li::marker {
  color: #0156ff;
}
.teacher-list ul li {
  list-style: none;
  position: relative;
  padding-left: 0.5rem;
}
.teacher-list ul li::after {
  content: '';
  position: absolute;
  left: -0.5rem;
  top: 0.8rem;
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 1rem;
  background: #0156ff;
}

// 全流程技术支撑
.yingjian-three {
  width: 15.4rem;
  // height: 21.45rem;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 0.5rem;
  margin: 0rem 1.2rem;
  margin-bottom: 2.8rem;
}
.yingjian-bj {
  background-image: url(../assets/scheme/bj2.png);
  width: 100%;
  // height: 27rem;
  margin-bottom: 2.8rem;
}
.yingjian-three-Img {
  text-align: center;
}
.yingjian-three-Img > img {
  width: 2.7rem;
  margin: 2rem 0rem 0.5rem;
}
.yingjian-three-title {
  height: 2.25rem;
  font-size: 1.6rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 2.25rem;
  text-align: center;
  margin-bottom: 1.2rem;
}
.yingjian-three-text {
  // height: 9rem;
  font-size: 1.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  padding: 0rem 1.2rem;
  text-align: justify;
}
.yingjian-three-box {
  display: -webkit-box;
  overflow-x: scroll;
  overflow-y: hidden;
  text-align: justify;
  box-sizing: border-box;
  padding-top: 3rem;
}

// 应用模式
.center-four-text {
  font-size: 1.4rem !important;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  padding: 0rem 1.2rem;
}
.center-four-img > img {
  width: 37.5rem;
  height: 33.3rem;
  margin: 3rem 0rem 2.65rem;
}

/deep/.elTabs ::-webkit-scrollbar {
  display: none;
}
/deep/.el-tabs__nav-wrap {
  overflow: hidden;
  margin-bottom: -0.05rem;
  position: relative;
}
/deep/ .el-tabs__nav-scroll {
  margin-top: 2rem;
  justify-content: space-around;
  padding-bottom: 1.1rem;
  overflow-x: scroll;
}
/deep/.el-tabs__nav-scroll::-webkit-scrollbar {
  display: none;
}
/deep/.el-tabs__item {
  font-size: 1.6rem;
  overflow-x: scroll;
  padding: 0px 1rem;
}

/deep/.el-carousel__button {
  background: #888;
}
/deep/.el-tabs__active-bar {
  margin-bottom: -1.1rem;
  background-color: #0156ff;
  z-index: 0;
}
/deep/.el-icon-arrow-left::before {
  display: none;
}
/deep/.el-icon-arrow-right::before {
  display: none;
}
/deep/.el-tabs__item.is-active {
  color: #0156ff;
}
/deep/.el-tabs__item:hover {
  color: #0156ff;
}

.el-timeline-hidden {
  height: 26rem;
}
</style>